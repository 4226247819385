<!-- eslint-disable max-len -->
<template>
  <div class="login">
    <b-row class="mt-5">
      <b-col md="8" offset-md="2" lg="6" offset-lg="3">
    <b-card title="login">
  <b-form>
  <b-form-group label="username">
    <b-form-input
      v-model="user.name"
      type="text"
      placeholder="input username..."
      ref="usernameInput"
      @keydown.enter="focusPasswordInput"
    ></b-form-input>
  </b-form-group>

  <b-form-group label="password">
    <b-form-input
      v-model="user.password"
      type="password"
      placeholder="input password..."
      @keydown.enter="login"
      ref="passwordInput"
    ></b-form-input>
  </b-form-group>
  <b-form-group>
  <b-button variant="outline-primary" block @click="login">Login</b-button>
</b-form-group>
</b-form>
</b-card>
</b-col>
</b-row>
<b-table striped hover :items="items"></b-table>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-useless-path-segments, import/newline-after-import
import about from './AboutView.vue';

export default {
  created() {
    about.exitloginshow = true;
    about.returnselectshow = true;
    about.setpassword = true;
    about.leftNavbar = false;
  },
  data() {
    return {
      about,
      user: {
        name: '',
        password: '',
      },
      items: [],
    };
  },
  mounted() {
    this.$refs.usernameInput.focus();
  },
  methods: {
    login() {
      const api = `${about.request}/api/auth/login`;
      this.axios.post(api, { ...this.user }).then((res) => {
        // 跳转主页,保存token
        localStorage.setItem('token', res.data);
        this.$router.replace({ name: 'setnode' });
      }).catch((err) => {
        this.$bvToast.toast('密码或用户名输入不正确', {
          title: err.response,
          variant: 'danger',
          solid: true,
        });
      });
    },
    focusPasswordInput() {
      this.$refs.passwordInput.focus();
    },
  },
};
</script>
  <style lang="scss" scoped>
