<template>
  <div id="app" :style="styleChange">

      <navBar />
      <LeftNavBar/>
      <b-container :class="containerClass">
        <router-view/>
      </b-container>

  </div>
</template>

<script>
import NavBar from './views/layout/NavBar.vue';
import LeftNavBar from './views/layout/LeftNavBar.vue';

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { NavBar, LeftNavBar },
  created() {
    this.styleChange.width = `${window.screen.width}px`;
  },
  computed: {
    containerClass() {
      return this.$route.name === 'ems' ? 'containerStyle' : '';
    },
  },
  data() {
    return {
      styleChange: {
        height: '',
        width: '',
      },
    };
  },
};
</script>
<style lang="scss">
.containerStyle{
  margin: 0 150px !important;
}
</style>
