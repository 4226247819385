<!-- eslint-disable max-len -->
<template>
  <div style="display:inline-block;
    width: 150px;
    height: 100%;
    position: absolute;
    left: 0px;
    background-color:#002137"
    :value = "selectactive"
    v-if="about.leftNavbar">
    <b-button-group vertical>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 1}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="node"
        >NODE DATA
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 2}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="rx"
        >GATEWAY DATA
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 3}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="stat"
        >GATEWAYSTAT DATA
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 4}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="otaa"
        >OTAA DATA
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 8}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="downcommand"
        id="downcommand">MQTTDownCommand
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 9}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="nodeaddr"
        id="nodeaddr">Node Online Date
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 10}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="gwlist"
        id="gwlist">AllGWFromNode
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 5}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="setnode"
        id="setnode">SETNODE
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 6}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="debugprojectlist"
        id="debugprojectlist">DEBUG PROJECT
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 7}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="notenumber"
        id="notenumber">ALARM MESSAGE
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 11}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="gwdistmap"
        id="gwdistmap">GWDISTMAP
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 12}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="installnode"
        id="installnode">INSTALL INFO
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 13}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="monitor"
        id="monitor">MONITOR
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 14}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="nodestatprolist"
        id="nodestatprolist">NODESTAT
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 15}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="calibrate"
        id="calibrate">calibrate
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 16}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="afterSale"
        id="AfterSale">AfterSale
      </a>
      <a href="#" class="btn btn-outline-primary" :class=" {active: liActiveClass == 17}" style="width: 150px;height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        justify-content: center;"
        @click="ems"
        id="AfterSale">EMS
      </a>
  </b-button-group>
    <!-- <b-navbar toggleable="lg" type="dark" variant="primary" style="">
    <b-navbar-brand href="#" @click="returnpro" >返回</b-navbar-brand>
   </b-navbar>
   <b-navbar toggleable="lg" type="dark" variant="info" style="">
    <b-navbar-brand href="#" @click="debug" >项目信息</b-navbar-brand>
   </b-navbar>
   <b-navbar toggleable="lg" type="dark" variant="warning" style="">
    <b-navbar-brand href="#" @click="errdata" >错误数据</b-navbar-brand>
   </b-navbar>
   <b-navbar toggleable="lg" type="dark" variant="success" style="">
    <b-navbar-brand href="#" @click="yesdata" >正确数据</b-navbar-brand>
   </b-navbar>
   <b-navbar toggleable="lg" type="dark" variant="dark" style="">
    <b-navbar-brand href="#" @click="nodeinfo" >节点信息</b-navbar-brand>
   </b-navbar> -->
  <!-- <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-brand href="#">NavBar</b-navbar-brand>
  </b-navbar> -->
</div>
</template>
<script>
import about from '../AboutView.vue';

export default {
  data() {
    return {
      liActiveClass: 0,
      about,
    };
  },
  computed: {
    selectactive() {
      this.selectmodule();
      return this.liActiveClass;
    },

  },
  created() {
    this.selectmodule();
  },
  methods: {
    selectmodule() {
      if (this.$route.name === 'node') {
        this.liActiveClass = 1;
      } else if (this.$route.name === 'rx') {
        this.liActiveClass = 2;
      } else if (this.$route.name === 'stat') {
        this.liActiveClass = 3;
      } else if (this.$route.name === 'otaa') {
        this.liActiveClass = 4;
      } else if (this.$route.name === 'setnode') {
        this.liActiveClass = 5;
      } else if (this.$route.name === 'debugprojectlist') {
        this.liActiveClass = 6;
      } else if (this.$route.name === 'notenumber') {
        this.liActiveClass = 7;
      } else if (this.$route.name === 'downcommand') {
        this.liActiveClass = 8;
      } else if (this.$route.name === 'nodeaddr') {
        this.liActiveClass = 9;
      } else if (this.$route.name === 'gwlist') {
        this.liActiveClass = 10;
      } else if (this.$route.name === 'gwdistmap') {
        this.liActiveClass = 11;
      } else if (this.$route.name === 'installnode') {
        this.liActiveClass = 12;
      } else if (this.$route.name === 'monitor') {
        this.liActiveClass = 13;
      } else if (this.$route.name === 'nodestatprolist') {
        this.liActiveClass = 14;
      } else if (this.$route.name === 'calibrate') {
        this.liActiveClass = 15;
      } else if (this.$route.name === 'afterSale') {
        this.liActiveClass = 16;
      } else if (this.$route.name === 'ems') {
        this.liActiveClass = 17;
      }
    },
    node() {
      this.liActiveClass = 1;
      if (this.$route.name === 'node') {
        return;
      }
      this.$router.push({ name: 'node' });
    },
    rx() {
      this.liActiveClass = 2;
      this.$router.push({ name: 'rx' });
    },
    stat() {
      this.liActiveClass = 3;
      if (this.$route.name === 'stat') {
        return;
      }
      this.$router.push({ name: 'stat' });
    },
    otaa() {
      this.liActiveClass = 4;
      if (this.$route.name === 'otaa') {
        return;
      }
      this.$router.push({ name: 'otaa' });
    },
    setnode() {
      this.liActiveClass = 5;
      if (this.$route.name === 'setnode') {
        return;
      }
      this.$router.push({ name: 'setnode' });
    },
    debugprojectlist() {
      this.liActiveClass = 6;
      if (this.$route.name === 'debugprojectlist') {
        return;
      }
      this.$router.push({ name: 'debugprojectlist' });
    },
    notenumber() {
      this.liActiveClass = 7;
      if (this.$route.name === 'notenumber') {
        return;
      }
      this.$router.push({ name: 'notenumber' });
    },
    downcommand() {
      this.liActiveClass = 8;
      if (this.$route.name === 'downcommand') {
        return;
      }
      this.$router.push({ name: 'downcommand' });
    },
    nodeaddr() {
      this.liActiveClass = 9;
      if (this.$route.name === 'nodeaddr') {
        return;
      }
      this.$router.push({ name: 'nodeaddr' });
    },
    gwlist() {
      this.liActiveClass = 10;
      if (this.$route.name === 'gwlist') {
        return;
      }
      this.$router.push({ name: 'gwlist' });
    },
    gwdistmap() {
      this.liActiveClass = 11;
      if (this.$route.name === 'gwdistmap') {
        return;
      }
      this.$router.push({ name: 'gwdistmap' });
    },
    installnode() {
      this.liActiveClass = 12;
      if (this.$route.name === 'installnode') {
        return;
      }
      this.$router.push({ name: 'installnode' });
    },
    monitor() {
      this.liActiveClass = 13;
      if (this.$route.name === 'monitorlist') {
        return;
      }
      this.$router.push({ name: 'monitorlist' });
    },
    nodestatprolist() {
      this.liActiveClass = 14;
      if (this.$route.name === 'nodestatprolist') {
        return;
      }
      this.$router.push({ name: 'nodestatprolist' });
    },
    calibrate() {
      this.liActiveClass = 15;
      if (this.$route.name === 'calibrate') {
        return;
      }
      this.$router.push({ name: 'calibrate' });
    },
    afterSale() {
      this.liActiveClass = 16;
      if (this.$route.name === 'afterSale') {
        return;
      }
      this.$router.push({ name: 'afterSale' });
    },
    ems() {
      this.liActiveClass = 17;
      if (this.$route.name === 'ems') {
        return;
      }
      this.$router.push({ name: 'ems' });
    },
  },
};
</script>
