<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<!-- eslint-disable max-len -->
<template>
<div>
  <b-navbar toggleable="lg" type="dark" style="background-color:cadetblue;">
    <b-navbar-brand  @mouseover="isHover = true" @mouseleave="isHover = false" @click="returnselect" :class="{ clickable: isHover }">LORAAUDIT</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <!-- Right aligned nav items -->
     <!-- <b-navbar-nav><b-nav-item href="#" v-if="!this.about.returnselectshow" @click="this.returnselect ">返回主页</b-nav-item></b-navbar-nav> -->
      <b-navbar-nav >
        <!-- <b-nav-item-dropdown text="导航">
          <b-dropdown-item v-if="!this.about.exitloginshow" @click="this.exitlogin">退出登录</b-dropdown-item>
          <b-dropdown-item v-if="!this.about.returnselectshow" @click="this.returnselect">返回选择类型界面</b-dropdown-item>
          <b-dropdown-item v-if="!this.about.setpassword" @click="this.setpassword">修改密码</b-dropdown-item>
        </b-nav-item-dropdown> -->

        <b-nav-item href="#" v-if="!this.about.exitloginshow" @click="this.exitlogin"><b-icon icon="power" aria-hidden="true"></b-icon> Logout</b-nav-item>
        <b-nav-item href="#" v-if="!this.about.setpassword" @click="this.setpassword"><b-icon icon="gear-fill" aria-hidden="true"></b-icon> Modify Password</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
import about from '../AboutView.vue';

export default {
  data() {
    return {
      isHover: false,
      about,
    };
  },
  methods: {
    exitlogin() {
      // 清空token退出
      this.about.returnselectshow = true;
      this.about.exitloginshow = true;
      this.about.setpassword = true;
      localStorage.setItem('token', '');
      this.$router.push({ name: 'home' });
    },
    setpassword() {
      // 清空token退出
      this.about.setpassword = true;
      this.about.returnselectshow = false;
      this.about.exitloginshow = false;
      this.$router.push({ name: 'setpassword' });
    },
    returnselect() {
      this.about.returnselectshow = true;
      this.about.exitloginshow = false;
      this.about.setpassword = false;
      this.$router.push({ name: 'setnode' });
    },

  },
};
</script>
<style>
.clickable {
  cursor: pointer;
  /* 添加其他点击效果样式，比如改变背景颜色或边框样式等 */
}
</style>
